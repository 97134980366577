import React, { Component } from "react";
import _ from "lodash";
import can_sms from "./images/sms.png";
import cannot_sms from "./images/nosms.png";
import BroadcastMessageAndAlertToWardenButtons from "./BroadcastMessageAndAlertToWardenButtons";
import { reloadPage } from "../../../utils";

class CreateEvent extends Component {
  state = {
    selectedButton: {
      everyone: false,
      noone: false,
      wardens: false,
    },
  };

  componentDidUpdate(prevProps) {
    if (
      _.get(prevProps, "activeEvent.active") === undefined &&
      _.get(this.props, "activeEvent.active") === true
    ) {
      const activeEventType = this.props.eventTypes.find(
        (eventType) => eventType.type === this.props.activeEvent.type
      );
      this.props.changeType(
        parseInt(activeEventType.id, 10),
        activeEventType.message
      );
    }
  }

  renderEventTypes() {
    if (
      this.props.activeEvent !== undefined &&
      this.props.activeEvent !== null
    ) {
      let eventType = this.props.activeEvent;
      return (
        <option
          value={eventType.id}
          key={
            eventType.id + " " + eventType.type + " " + eventType.description
          }
        >
          {eventType.type} - Current Event
        </option>
      );
    } else {
      return this.props.eventTypes.map((eventType) => {
        return (
          <option
            value={eventType.id}
            key={
              eventType.id + " " + eventType.type + " " + eventType.description
            }
          >
            {eventType.type}
          </option>
        );
      });
    }
  }

  renderInitiateEventButton = () => {
    const {
      userInputBuildings,
      isAllActiveEventBuildingAreSelected,
      isSomeActiveEventBuildingAreSelected,
      isSomeNonActiveEventBuildingAreSelected,
      activeEvent,
      showModal,
      showEndModal,
      input,
    } = this.props;

    const isEventActive = _.get(activeEvent, "active", false) === true;

    const isInputBuildingsSelected =
      _.get(userInputBuildings, "length", []) > 0;

    const isAllInputFieldsBeenFilledIn = Object.values({
      buildings: Boolean(_.get(input, "buildings")),
      eventType: Boolean(_.get(input, "eventType")),
      message: Boolean(_.get(input, "message")),
    }).every((value) => value === true);

    const primaryButtonLabel =
      isAllActiveEventBuildingAreSelected && isEventActive
        ? "End event"
        : isSomeActiveEventBuildingAreSelected && isEventActive
        ? "Remove building(s)"
        : isSomeNonActiveEventBuildingAreSelected && isEventActive
        ? "Add building(s)"
        : "Start Event";

    const primaryButtonAction =
      isAllActiveEventBuildingAreSelected && isEventActive
        ? showEndModal // End Event
        : isSomeActiveEventBuildingAreSelected && isEventActive
        ? showEndModal // Remove building(s)
        : showModal; // Add Event

    return isInputBuildingsSelected ? (
      <div className="event__actions mt-10">
        <button className="event__actions-secondary-btn" onClick={reloadPage}>
          Cancel
        </button>

        <button
          className="event__actions-primary-btn"
          style={{
            border: isAllInputFieldsBeenFilledIn
              ? "2px solid #00B050"
              : "2px solid #e8e9ed",
            backgroundColor: isAllInputFieldsBeenFilledIn
              ? "#00B050"
              : "#E7E7E8",
            color: isAllInputFieldsBeenFilledIn ? "#ffffff" : "#969bab",
          }}
          onClick={primaryButtonAction}
          disabled={!isAllInputFieldsBeenFilledIn}
        >
          {primaryButtonLabel}
        </button>
      </div>
    ) : null;
  };

  handleTypeChange = (e) => {
    const type = this.props.eventTypes.find((type) => {
      return type.id === parseInt(e.target.value, 10);
    });
    this.props.changeType(parseInt(e.target.value, 10), type.message);
  };

  handleMessageChange = (e) => {
    this.props.changeMessage(e.target.value);
  };

  renderCanSms = () => {
    let image_src = this.props.sms_allowed ? can_sms : cannot_sms;
    return <img src={image_src} alt="sms_status" />;
  };

  render() {
    const isEventNotActive =
      _.get(this.props, "activeEvent.active", false) === false;

    return (
      <div className="row mt-20">
        <div
          className="col s12"
          style={{
            display: _.get(this.props, "activeEvent.id")
              ? "none"
              : "inline-block",
          }}
        >
          <label className="event__createForm--label event__typeSelect">
            Event type:
            <select
              className="browser-default mt-10"
              defaultValue="default"
              style={{
                border:
                  Boolean(_.get(this.props, "input.eventType")) &&
                  "2px solid #00B050",
              }}
              onChange={this.handleTypeChange}
              value={_.get(this.props, "activeEvent.id") || undefined}
            >
              <option value="default" disabled>
                {this.props.loading === true
                  ? "Loading..."
                  : "Select event type"}
              </option>
              {this.renderEventTypes()}
            </select>
          </label>
        </div>

        <div className="col s12">
          <label className="event__createForm--label event__message">
            Broadcast message:
            <textarea
              className="event__message--textarea mt-10"
              onChange={this.handleMessageChange}
              value={this.props.input.message}
              style={{
                border:
                  Boolean(_.get(this.props, "input.message")) &&
                  "2px solid #00B050",
              }}
            />
          </label>
        </div>

        <div className="col s12">
          <BroadcastMessageAndAlertToWardenButtons
            broadcastMessage={this.props.broadcastMessage}
            alertToWarden={this.props.alertToWarden}
            handleChangeBroadcastMessage={
              this.props.handleChangeBroadcastMessage
            }
            handleChangeAlertToWarden={this.props.handleChangeAlertToWarden}
            showAlertToWardenToggle={isEventNotActive}
          />
        </div>

        <div className="col s12 mt-40">{this.renderInitiateEventButton()}</div>
      </div>
    );
  }
}

export default CreateEvent;
